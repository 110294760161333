<div mat-dialog-title style="text-align: right">
  <mat-icon aria-hidden="false" class="mtdc" mat-dialog-close aria-label="Example close icon"
    >cancel</mat-icon
  >
</div>
<mat-dialog-content #dialog class="mat-typography">
  <div class="row">
    <div class="col-2">
   
     <div class="profile"> <i class="iconprofile fa fa-user-circle"></i>
     </div>
        <div class="icon1profile"></div>
    </div>
    <div class="col-10 ">
      <div class="row">
       
        <div class="col-12 tc1 colt2" >
            
                <!-- the same view can be shown in more than one case -->
                <span >{{data.usuario?.NOMBRE}}</span>
               
               
        </div>
      </div>
      <div class="row">
        <div class="col-12 tc2 colt2" >
            <span>{{data.usuario?.TIPO_AGENTE}}</span>
     
        </div>
      </div>
      <div class="row ems">
      
        <div class="col-11 tc3 colt" >
            <span >{{data.usuario?.CORREO}}</span>
      
        </div>
      </div>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-2"></div>
    <div class="col-10">
      <div class="row">
        <div class="col-1 colt1">
          <i class="fa fa-phone-square ic1"></i>
        </div>
        <div class="col-11">
          <div class="row">
            <div class="col-3 colt">
              <div class="tc3"  >
                <span >{{data.unidad?.TELEFONO_CONVENCIONAL?data.item.TELEFONO_CONVENCIONAL:'-'}}</span>
       

              </div>
              <div class="tc2">PRINCIPAL</div>
            </div>
            <div class="col-3 colt blat">
              <div class="tc3" >
                <span >{{data.unidad?.TELEFONO_PRINCIPAL?data.unidad?.TELEFONO_PRINCIPAL:'-'}}</span>
              

              </div>
              <div class="tc2">SECUNDARIO</div>
            </div>
            <div class="col-3 colt">
              <div class="tc3" >
                <span >{{data.unidad?.TELEFONO_SECUNDARIO?data.unidad?.TELEFONO_SECUNDARIO:'-'}}</span>
               
              </div>
              <div class="tc2">MOVIL</div>
            </div>
            <div class="col-3 colt">
              <div class="tc3" [ngSwitch]="data.tipo">
                <span *ngSwitchCase="1">{{data.unidad?.TELEFONO_SECUNDARIO?data.unidad?.TELEFONO_SECUNDARIO:''}}</span>
  
              </div>
              <div class="tc4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-2"></div>
    <div class="col-10">
      <div class="row">
        <div class="col-1 colt1">
          <i class="fa fa-map-marker ic1"></i>
        </div>
        <div class="col-9 colt">
          <div>{{data.unidad?.DIRECCION}}</div>
        </div>
      </div>
    </div>
  </div>
  <hr />

</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <div class="row">
    <div class="col-12">
      <span class="tc4">JEFE REGIONAL</span>
    </div>
    <div class="col-12">
      <span>{{data.item?.JEFE_REGIONAL}}</span>
    </div>
    <div class="col-12">
      <i class="icondr fa fa-phone"></i>
      <span class="tc3">{{data.item?.CELULAR_REGIONAL}}</span>
    </div>
  </div> -->
</mat-dialog-actions>
