import { Component, OnInit, ViewChild } from '@angular/core';
import { TarjetaComponent } from '../tarjeta/tarjeta.component';
import { VCardFormatter } from 'ngx-vcard';
import { DirectorioService } from '../directorio.service';
import { MatDialog } from '@angular/material/dialog';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.styl']
})
export class DirectorioComponent implements OnInit {
  unidades: any[];

  constructor(private directorio: DirectorioService, public dialogo: MatDialog) { }
  title = 'front-directorio';
  personas: any[] = [];
  personatemp: any[] = [];
  agencias = [];
  vcard = {};
  myControl;
  // @ViewChild('vcard')
  // cvcard;
  @ViewChild('fagen')
  fagen;
  faCoffee = faSearch;
  filteredVariables;
  filtroagencia;
  timer = null;
  extensiones;
  fextensiones = [];
  ngOnInit(): void {
    // this.directorio.get_general().then((resp) => {
    //   // this.extensiones = resp;
    //   let ext: any = JSON.parse(resp);
    //   // console.log(resp)
    //   this.extensiones = Object.keys(ext.UNIDADES_DE_GESTION_USUARIOS).map(key => ext.UNIDADES_DE_GESTION_USUARIOS[key])

    //   //console.log(this.personas)
    // });
    // this.directorio.get_extensiones().then(resp => {
    //   this.extensiones = resp;
    //   console.log(this.extensiones)

    //   //console.log(this.personas)
    // });
    // this.directorio.get_directorio().then(resp => {
    //   this.personatemp = resp;
    //   for (const iterator of this.personatemp) {
    //     this.agencias.push(iterator.AGENCIA);

    //   }

    //   //console.log(this.personas)
    // });
  }
  download() {
    // let vCard: VCard = {
    //   name: {
    //     firstNames: "John",
    //     lastNames: "Doe",
    //   },
    // };

    VCardFormatter.getVCardAsString(this.vcard);
  }
  //   _timeout;
  //   displayName() {
  //     this._timeout  = null;
  //     if(this._timeout){ //if there is already a timeout in process cancel it
  //       window.clearTimeout(this._timeout);
  //     }
  //     this._timeout = window.setTimeout(() => {
  //       //  this._timeout = null;
  //       //  this.lc.run(() => this.name1 = this.name);
  //     },1000);
  //  }
  filtronombre;
  filtronombre1;
  filtroExtension() {

    this.directorio.buscarExtensiones(this.filtronombre1).then(resp => {
      console.log(resp);
      this.unidades = resp;
      console.log(this.unidades)
    })

  }
  filtroAgencia() {

    this.directorio.buscarAgencia(this.filtronombre).then(resp => {
      console.log(resp);
      this.unidades = resp;
      console.log(this.unidades)
    })

  }
  filtrar() {

    this.personas = this.personatemp.filter(r => {
      if (!this.filtronombre || this.filtronombre.length === 0) {
        return false;
      }
      // split search text on space
      const searchTerms = this.filtronombre.split(' ');
      const searchfind = r.JEFE;
      const searchfind1 = r.SUBJEFE;
      const searchfind2 = r.JEFE_REGIONAL;

      // search for single terms.
      // this reduces the item list step by step
      let aux = false;
      let aux1 = false;
      let aux2 = false;
      for (const iterator of searchTerms) {
        if (searchfind) {
          aux = this.filtro(searchfind, iterator);

        }
        if (searchfind1) {
          aux1 = this.filtro(searchfind1, iterator);
        }
        if (searchfind2) {
          aux2 = this.filtro(searchfind2, iterator);
        }
      }


      return aux || aux1 || aux2;
    });
  }
  filtro(t1, t2) {
    if (t1) {
      if (t1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(t2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) != -1) {
        return true;
      } else {
        return false;
      }
    }
  }


  filtrarAgencia() {

    this.personas = this.personatemp.filter(r => {
      if (!this.filtroagencia || this.filtroagencia.length === 0) {
        return false;
      }
      // split search text on space
      const searchTerms = this.filtroagencia.split(' ');
      const searchfind = r.AGENCIA;


      // search for single terms.
      // this reduces the item list step by step
      let aux = false;
      for (const iterator of searchTerms) {
        if (searchfind) {
          if (searchfind.toLowerCase().indexOf(iterator.toLowerCase()) != -1) {
            aux = true;
          };
        }

      }
      return aux;
    });
  }
  limpiar() {
    this.filtroagencia = null;
    this.filtronombre = null;
    this.filtrarAgencia();
    this.filtrar();
  }
  terminaEscribir() {
    clearTimeout(this.timer);
    this.timer = setTimeout(r => {
      this.filtroAgencia();
    }, 1000)
  }
  terminaEscribirAgencia(fagen) {

    clearTimeout(this.timer);

    this.timer = setTimeout(r => {

      this.filtrarAgencia();
    }, 1000)
  }
  openDialog(item, item1) {
    let data1: any = {};
    data1.usuario = item;
    data1.unidad = item1.unidad;


    this.dialogo.open(TarjetaComponent, {
      data: data1,
      minWidth: '500px',
      maxWidth: '554px',
      minHeight: '370px'
    });
  }
  iniciales(texto) {
    let arr = texto.split(' ');
    return arr[2].substr(0, 1) + arr[0].substr(0, 1);
  }

}
