import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectorioService {

  constructor(private httpclient: HttpClient) { }
  get_directorio() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'Authorization': 'Basic ' + btoa(environment.autorizacion),
        'x-api-key': environment.token
      })
    };
    return this.httpclient.get<any[]>(environment.urlServicio).toPromise();
  }
  get_extensiones() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'Authorization': 'Basic ' + btoa(environment.autorizacion),
        'x-api-key': environment.token
      })
    };
    return this.httpclient.get<any[]>(environment.urlExtensiones).toPromise();
  }

  get_cumple() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'Authorization': 'Basic ' + btoa(environment.autorizacion),
        'x-api-key': environment.token
      })
    };
    return this.httpclient.get<any[]>(environment.urlCumpleg).toPromise();
  }

  get_general() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'Authorization': 'Basic ' + btoa(environment.autorizacion),
        'x-api-key': environment.token
      })
    };
    return this.httpclient.get<any>(environment.urlCumpleg).toPromise();
  }
  get_dir() {

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'text/plain',
    //     'Authorization': 'Basic ' + btoa(environment.autorizacion),
    //     'x-api-key': environment.token
    //   })
    // };
    return this.httpclient.get<any>(environment.urlDir).toPromise();
  }
  get_dir1(id) {

    return this.httpclient.get<any>(environment.urlDir+id+".json").toPromise();
  }
  buscarExtensiones(nombre) {

    return this.httpclient.get<any[]>(environment.urlServicioi + 'buscarNombre/' + nombre).toPromise();
  }
  buscarAgencia(nombre) {

    return this.httpclient.get<any[]>(environment.urlServicioi + 'buscarAgencias/' + nombre).toPromise();
  }
}

