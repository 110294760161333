import { Component, OnInit } from '@angular/core';
import { DirectorioService } from '../directorio.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cumple',
  templateUrl: './cumple.component.html',
  styleUrls: ['./cumple.component.styl'],
})
export class CumpleComponent implements OnInit {
  cumples = [];
  fcumples = [];
  personatemp = [];
  constructor(private directorio: DirectorioService,private route: ActivatedRoute) {}

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id')

    this.directorio.get_dir1(id).then((resp) => {
      // for (const iterator of resp.data) {
      //   console.log()
      //   console.log(moment(iterator.perFechaNac, 'YYYYMMDD').toDate())

      // }
      var curr = new Date(); // get current date
      var first = curr.getDate() - 1; // First day is the day of the month - the day of the week
      var last = first + 3; // last day is the first day + 6
      var mfirst = moment().subtract(1, 'days').toDate()
      mfirst.setHours(0);
      mfirst.setMinutes(0);
      mfirst.setSeconds(0);
      mfirst.setMilliseconds(0);
      var mlast = moment().add(3, 'days').toDate()
      mlast.setHours(0);
      mlast.setMinutes(0);
      mlast.setSeconds(0);
      mlast.setMilliseconds(0);
      // console.log(mfirst.getTime())
      // console.log(mlast.getTime())
      // console.log(first);
      // console.log(last);
      // var firstday = new Date(curr.setDate(first)).toUTCString();
      // var lastday = new Date(curr.setDate(last)).toUTCString();

      // firstday
      // "Sun, 06 Mar 2011 12:25:40 GMT"
      // lastday
      // "Sat, 12 Mar 2011 12:25:40 GMT"
      const d = new Date();
      let year = d.getFullYear();
      let m = d.getMonth();
      let f = resp.data.filter((a) => {
        let fa = moment(a.perFechaNac, 'YYYYMMDD').toDate();
        // console.log(a.perFechaNac);
        // console.log(fa.getDate());
        // console.log(a.eplEstado);
        fa.setFullYear(year)
        //console.log(fa.getTime()+" > " +mfirst.getTime())
        return fa.getTime()  >= mfirst.getTime() &&
          fa.getTime()  < mlast.getTime()
          ? a
          : null;
      });
      // console.log(f);
      // console.log(this.fecha(resp.perFechaNac))
      // this.cumples = f.sort((a, b) => (a.perFechaNac > b.perFechaNac) ? 1 : -1);
      for (let d = mfirst; d < mlast; d.setDate(d.getDate() + 1)) {
        // console.log(d)
        let index = d.getDate()
      // for (let index = first; index <= last; index++) {
        // console.log(d.getDate());
        // console.log(d.getMonth())
        // console.log(
        //   f.filter(
        //     (a) => moment(a.perFechaNac, 'YYYYMMDD').toDate().getDate() == index
        //   )
        // );
        console.log()
        let cum = {
          fecha: new Date(year, d.getMonth(), index),
          fechas: moment(new Date(year, d.getMonth(), index))
            .locale('es')
            .format('MMMM DD'),
          usuarios: f
            .filter(
              (a) =>
                moment(a.perFechaNac, 'YYYYMMDD').toDate().getDate() == index
            )
            .sort((a, b) => {
              if (a.perPrimerApellido < b.perPrimerApellido) {
                return -1;
              }
              if (a.perPrimerApellido > b.perPrimerApellido) {
                return 1;
              }
              return 0;
            }),
        };
        this.cumples.push(cum);
      }
      // for (const iterator of this.cumples) {
      //   console.log(iterator);
      //   // for (const persona of iterator.usuarios) {
      //   //   persona.REGIONAL = iterator.unidad.REGIONAL;
      //   //   persona.EMPRESA = iterator.unidad.NOMBRE;
      //   //   this.fcumples.push(persona);
      //   // }
      //   let cumple = {}
      //   this.fcumples.push(iterator)

      // }
      // console.log(this.fcumples);
    });
  }
  fecha(fecha) {
    // console.log(fecha)
    // console.log(
    //   moment(fecha )
    //     .locale('es')
    //     .format('DD-MM')
    // );
    return moment(fecha)
      .locale('es')
      .format('dddd');
  }
  actual(date) {
    // console.log(date);
    const today = new Date();

    // 👇️ Today's date
    // console.log(today);

    if (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    ) {
      return true;
    }

    return false;
  }
}
