<div class="container">
    <div class="row">
      <div class="col-12 titulo1">
        DIRECTORIO DE EXTENSIONES
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <label class="b1" for="">Digite el nombre a buscar</label>
      </div>
      <div class="col-md-9 col-sm-6">
        <div class="form-group">
          <!-- <input
          #nombre
            type="text"
            class="form-control t1"
            name=""
            id=""
            (change)="filtrar()"
            [(ngModel)]="filtronombre"
            (keyup)="terminaEscribir()"
            aria-describedby="helpId"
            placeholder=""
            (focus)="limpiar()"
  
          /> -->
          <input
          #nombre
            type="text"
            class="form-control t1"
            name=""
            id=""
            (change)="filtroExtension()"
            [(ngModel)]="filtronombre1"
            (keyup.enter)="filtroExtension()"
            aria-describedby="helpId"
            placeholder=""
            (focus)="limpiar()"
  
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 titulo1">
        DIRECTORIO DE AGENCIAS
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text sp1" id="basic-addon1">
              <fa-icon [icon]="faCoffee"></fa-icon>
  
            </span>
          </div>
          <!-- <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of agencias" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-form-field>
            <mat-select>
              <mat-select-filter [placeholder]="'Filtrar..'" [array]="filteredVariables" (filteredReturn)="agencias = $event"></mat-select-filter>
              <mat-option *ngFor="let variable of filteredVariables">
                {{variable}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <!-- 
(keyup)="terminaEscribirAgencia(fagen)"
 [(ngModel)]="filtroagencia"
           -->
          <input 
          #fagen
            type="text"
            
            class="form-control tb2"
            [(ngModel)]="filtronombre"
            (keyup)="terminaEscribir()"
           
            placeholder=""
            matInput
            aria-label="Username"
            (focus)="limpiar()"
            aria-describedby="basic-addon1"
          />
  
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1">
              GO
            </span>
          </div>
        </div>
      </div>
    </div>
  
    <div id="accordion">
      <div class="">
        <div class="" id="headingOne">
          <h5 class="mb-0">
            <a
              class="btn btn-link blink"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Agencias
          </a>
          </h5>
        </div>
  
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            
            <div class="row" *ngFor="let item1 of unidades; let i=index">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 tl1">
                    {{item1.unidad.NOMBRE}}
                  </div>
                 
                </div>
               
                <div class="row r1" *ngFor="let item of item1.usuarios" >
                  <div class="col-2">
                    <!-- <i class="iconprofile fa fa-user-circle"></i> -->
                    <span class="i-circle">{{iniciales(item.NOMBRE)}}</span>

                  </div>
                  <div class="col-6">
                    <div class="tus1 row">
                      {{item.NOMBRE}}
                    
                    </div>
                    <div class="tus2 row">
                      {{item.TIPO_AGENTE}}
                                        </div>
                  </div>
                  
                  <div class="col-4 ">
                    <ul class="list-group list-group-horizontal">
                      <li class="list-group-item" (click)="openDialog(item,item1)">
                        <i class="icondr fa fa-address-card"></i>

                      </li>
                      <li class="list-group-item" *ngIf="item.CORREO">
                        <a target="_top" href="mailto:{{item.CORREO}}"><i class="icondr fa fa-envelope"></i></a>
                      </li>
                      <!-- <li class="list-group-item">
                        <a href="tel:{{item.CELULAR_JEFE}}"> <i class="icondr fa fa-phone-square"></i></a>
                      </li> -->
      
                      <li class="list-group-item">
                        <a href="http://www.google.com/maps/place/{{item1.unidad.GEO_X}},{{item1.unidad.GEO_Y}}"  target="_blank"> <i class="icondr fa fa-map-marker"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
                
                
              </div>
            </div>
            
         
      <!-- <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Cartera
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div class="card-body">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
            single-origin coffee nulla assumenda shoreditch et. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
            sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
            you probably haven't heard of them accusamus labore sustainable VHS.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Abastecimiento
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
            single-origin coffee nulla assumenda shoreditch et. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
            sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
            occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt
            you probably haven't heard of them accusamus labore sustainable VHS.
          </div>
        </div>
      </div> -->
    </div>
  </div>
  