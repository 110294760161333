import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { DirectorioService } from './directorio.service';
import { HttpHeaders } from '@angular/common/http';
import { VCardFormatter } from 'ngx-vcard';
import { TestBed } from '@angular/core/testing';
import { JsonPipe } from '@angular/common';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TarjetaComponent } from './tarjeta/tarjeta.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    
  }
  
}
