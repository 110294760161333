import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DirectorioComponent } from './directorio/directorio.component';
import { CumpleComponent } from './cumple/cumple.component';


const routes: Routes = [{ path: 'dir', component: DirectorioComponent },
{ path: 'cump/:id', component: CumpleComponent },
{ path: '', component: CumpleComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
