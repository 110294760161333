// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlServicio: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/directorio',
  urlExtensiones: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/extensiones',
  urlCumple: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/cumple',
  urlServicioi: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/',
  urlCumpleg: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/cumplegn',
  urlDir: 'assets/service',
  urlDir1: 'https://intranet.consenso.com.ec/ci/index.php/corporativo/cumplegn/',
  autorizacion: 'sap:ZU5HBZ7BH3349356',
  token: 'ucDqnZEJ.m9dGCpzNMbIt2QPreiS7daENQP8dzHEF'
};

/*
        'Authorization': 'Basic ' + btoa('sap:ZU5HBZ7BH3349356'),
        'x-api-key': 'ucDqnZEJ.m9dGCpzNMbIt2QPreiS7daENQP8dzHEF'
      })
    };
    return this.httpclient.get<any[]>('http://localhost/ci/index.php/corporativo/directorio').toPromise();
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
